<template>
<div class="card">
<img style="width: 40px; height: 40px" :src="icon" alt="icon" />
  <div class="tw-flex tw-flex-col tw-items-start tw-ml-2">
    <h5 class="card-title"><span v-if="desc === 'revenue'">&#8358; </span>{{title}}</h5>
    <h6 class="card-desc text-capitalize">{{desc}}</h6>
  </div>
</div>
</template>

<script>
export default {
  name: "IconAndText",
  props:{
    icon: [String],
    title: [String],
    desc: [String]
  }
}
</script>

<style scoped lang="scss">
.card{
  background: #FDFFFC;
  box-shadow: 0 4px 40px rgba(79, 79, 79, 0.02);
  border-radius: 10px;
  min-height: 100px;
  width: 212px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;

  @media screen and (min-width: 1900px) {
    width: 280px;
  }
}

.card-title{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
}

.card-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #4F4F4F;
}
</style>