<template>
<div class="tw-flex tw-w-full tw-flex-col tw-px-10">
  <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
    <icon-and-text  :icon="transportCompanyIcon" title="5,000" desc="Transport Companies" />
    <icon-and-text  :icon="customersIcon" title="70,500" desc="Customers"/>
    <icon-and-text  :icon="travel_techIcon" title="3,500" desc="Travel-Tech"/>
    <icon-and-text  :icon="bookingsIcon" title="600,000" desc="bookings" />
    <icon-and-text  :icon="revenueIcon" title="100,000,000" desc="revenue"/>
  </div>

  <div class="tw-flex tw-w-full tw-flex-row tw-my-10 tw-justify-between">
    <div class="tw-hidden md:tw-flex tw-bg-white card companies-card">
        <div class="tw-p-4 tw-w-full">
          <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
            <p class="mb-0 tw-px-0 card-title">
              Companies
            </p>
            <p class="mb-0 tw-px-0 tw-cursor-pointer card-sub-title" >
              View
            </p>
          </div>
          <loader />
          <div  class="manager-card">
            <div class="tw-flex tw-flex-col tw-w-full " v-for="(company, index) in companies" :key="index">
              <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
                <img class="img-css" :src="company.profileImage" alt="image">
                <h6 class="desc-text">{{company.firstname && $vuetify.breakpoint.smAndUp
                    ? company.firstname : company.firstname.substring(0,6)+'...'}}</h6>
                <h6 class="desc-text">{{company.terminalAssignedName && company.terminalAssignedName.length &lt; 6
                    ? company.terminalAssignedName : company.terminalAssignedName.substring(0,6)+'...'}}</h6>
                <v-menu bottom offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <td class="d-flex justify-center desc-text">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon >mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <v-list>
                    <v-list-item  >
                      <v-list-item-title
                          style="color: black"
                          class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text">
                        View
                        <v-icon class="tw-p-0 tw-m-0" style="height: 20px!important;" >mdi-arrow-right</v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-divider class="tw-mt-3" />
            </div>
          </div>
        </div>
    </div>

    <div class="tw-hidden md:tw-flex tw-bg-white tw-w-4/12 card">
      <div class="tw-p-4 tw-w-full">
        <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
          <p class="mb-0 tw-px-0 card-title">
            Companies
          </p>
          <p class="mb-0 tw-px-0 tw-cursor-pointer card-sub-title">
            View
          </p>
        </div>
        <loader />
        <div  class="manager-card">
          <div class="tw-flex tw-flex-col tw-w-full " v-for="(company, index) in companies" :key="index">
            <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
              <img class="img-css" :src="company.profileImage" alt="image">
              <h6 class="desc-text">{{company.firstname && $vuetify.breakpoint.smAndUp
                  ? company.firstname : company.firstname.substring(0,6)+'...'}}</h6>
              <h6 class="desc-text">{{company.terminalAssignedName && company.terminalAssignedName.length &lt; 6
                  ? company.terminalAssignedName : company.terminalAssignedName.substring(0,6)+'...'}}</h6>
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center desc-text">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon >mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item  >
                    <v-list-item-title
                        style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text">
                      View
                      <v-icon class="tw-p-0 tw-m-0" style="height: 20px!important;" >mdi-arrow-right</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-divider class="tw-mt-3" />
          </div>
        </div>
      </div>
    </div>

    <div class=" tw-hidden md:tw-flex tw-bg-white tw-w-2/12 card">
      <div class="tw-p-4 tw-w-full">
        <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
          <p class="mb-0 tw-px-0 card-title">
            Bookings
          </p>
          <p class="mb-0 tw-px-0 tw-cursor-pointer card-sub-title" >
            View
          </p>
        </div>
        <loader />
        <div  class="manager-card">
          <div class="tw-flex tw-flex-col tw-w-full " v-for="(company, index) in companies" :key="index">
            <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
              <img class="img-css" :src="company.profileImage" alt="image">
              <h6 class="desc-text">{{company.firstname && $vuetify.breakpoint.smAndUp
                  ? company.firstname : company.firstname.substring(0,6)+'...'}}</h6>
              <h6 class="desc-text">{{company.terminalAssignedName && company.terminalAssignedName.length &lt; 6
                  ? company.terminalAssignedName : company.terminalAssignedName.substring(0,6)+'...'}}</h6>
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center desc-text">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon >mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item  >
                    <v-list-item-title
                        style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text">
                      View
                      <v-icon class="tw-p-0 tw-m-0" style="height: 20px!important;" >mdi-arrow-right</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-divider class="tw-mt-3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import transportCompanyIcon from "@/assets/transportCompany.svg"
import customersIcon from "@/assets/customers.svg"
import travel_techIcon from "@/assets/travel_tech.svg"
import bookingsIcon from "@/assets/bookings.svg"
import revenueIcon from "@/assets/revenue.svg"

import IconAndText from "@/components/reuseables/IconAndText";
import Loader from "@/components/reuseables/Loader";
export default {
  name: "MainDashboard",
  components: {Loader, IconAndText},
  data(){
    return{
      transportCompanyIcon,
      customersIcon,
      travel_techIcon,
      bookingsIcon,
      revenueIcon,
      companies:[]
    }
  }
}
</script>

<style scoped lang="scss">
.companies-card{
  width: 45%;
}
.card{
  border-radius: 10px;
}

.card-title{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004AAD;
}

.card-sub-title{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #004AAD;
}
</style>