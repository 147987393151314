var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-flex-col tw-px-10"},[_c('div',{staticClass:"tw-flex tw-w-full tw-flex-row tw-justify-between"},[_c('icon-and-text',{attrs:{"icon":_vm.transportCompanyIcon,"title":"5,000","desc":"Transport Companies"}}),_c('icon-and-text',{attrs:{"icon":_vm.customersIcon,"title":"70,500","desc":"Customers"}}),_c('icon-and-text',{attrs:{"icon":_vm.travel_techIcon,"title":"3,500","desc":"Travel-Tech"}}),_c('icon-and-text',{attrs:{"icon":_vm.bookingsIcon,"title":"600,000","desc":"bookings"}}),_c('icon-and-text',{attrs:{"icon":_vm.revenueIcon,"title":"100,000,000","desc":"revenue"}})],1),_c('div',{staticClass:"tw-flex tw-w-full tw-flex-row tw-my-10 tw-justify-between"},[_c('div',{staticClass:"tw-hidden md:tw-flex tw-bg-white card companies-card"},[_c('div',{staticClass:"tw-p-4 tw-w-full"},[_vm._m(0),_c('loader'),_c('div',{staticClass:"manager-card"},_vm._l((_vm.companies),function(company,index){return _c('div',{key:index,staticClass:"tw-flex tw-flex-col tw-w-full "},[_c('div',{staticClass:"tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 "},[_c('img',{staticClass:"img-css",attrs:{"src":company.profileImage,"alt":"image"}}),_c('h6',{staticClass:"desc-text"},[_vm._v(_vm._s(company.firstname && _vm.$vuetify.breakpoint.smAndUp ? company.firstname : company.firstname.substring(0,6)+'...'))]),_c('h6',{staticClass:"desc-text"},[_vm._v(_vm._s(company.terminalAssignedName && company.terminalAssignedName.length < 6 ? company.terminalAssignedName : company.terminalAssignedName.substring(0,6)+'...'))]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-center desc-text"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text",staticStyle:{"color":"black"}},[_vm._v(" View "),_c('v-icon',{staticClass:"tw-p-0 tw-m-0",staticStyle:{"height":"20px!important"}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"tw-mt-3"})],1)}),0)],1)]),_c('div',{staticClass:"tw-hidden md:tw-flex tw-bg-white tw-w-4/12 card"},[_c('div',{staticClass:"tw-p-4 tw-w-full"},[_vm._m(1),_c('loader'),_c('div',{staticClass:"manager-card"},_vm._l((_vm.companies),function(company,index){return _c('div',{key:index,staticClass:"tw-flex tw-flex-col tw-w-full "},[_c('div',{staticClass:"tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 "},[_c('img',{staticClass:"img-css",attrs:{"src":company.profileImage,"alt":"image"}}),_c('h6',{staticClass:"desc-text"},[_vm._v(_vm._s(company.firstname && _vm.$vuetify.breakpoint.smAndUp ? company.firstname : company.firstname.substring(0,6)+'...'))]),_c('h6',{staticClass:"desc-text"},[_vm._v(_vm._s(company.terminalAssignedName && company.terminalAssignedName.length < 6 ? company.terminalAssignedName : company.terminalAssignedName.substring(0,6)+'...'))]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-center desc-text"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text",staticStyle:{"color":"black"}},[_vm._v(" View "),_c('v-icon',{staticClass:"tw-p-0 tw-m-0",staticStyle:{"height":"20px!important"}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"tw-mt-3"})],1)}),0)],1)]),_c('div',{staticClass:" tw-hidden md:tw-flex tw-bg-white tw-w-2/12 card"},[_c('div',{staticClass:"tw-p-4 tw-w-full"},[_vm._m(2),_c('loader'),_c('div',{staticClass:"manager-card"},_vm._l((_vm.companies),function(company,index){return _c('div',{key:index,staticClass:"tw-flex tw-flex-col tw-w-full "},[_c('div',{staticClass:"tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 "},[_c('img',{staticClass:"img-css",attrs:{"src":company.profileImage,"alt":"image"}}),_c('h6',{staticClass:"desc-text"},[_vm._v(_vm._s(company.firstname && _vm.$vuetify.breakpoint.smAndUp ? company.firstname : company.firstname.substring(0,6)+'...'))]),_c('h6',{staticClass:"desc-text"},[_vm._v(_vm._s(company.terminalAssignedName && company.terminalAssignedName.length < 6 ? company.terminalAssignedName : company.terminalAssignedName.substring(0,6)+'...'))]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-center desc-text"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text",staticStyle:{"color":"black"}},[_vm._v(" View "),_c('v-icon',{staticClass:"tw-p-0 tw-m-0",staticStyle:{"height":"20px!important"}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"tw-mt-3"})],1)}),0)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-justify-between tw-w-full tw-pb-3"},[_c('p',{staticClass:"mb-0 tw-px-0 card-title"},[_vm._v(" Companies ")]),_c('p',{staticClass:"mb-0 tw-px-0 tw-cursor-pointer card-sub-title"},[_vm._v(" View ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-justify-between tw-w-full tw-pb-3"},[_c('p',{staticClass:"mb-0 tw-px-0 card-title"},[_vm._v(" Companies ")]),_c('p',{staticClass:"mb-0 tw-px-0 tw-cursor-pointer card-sub-title"},[_vm._v(" View ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-justify-between tw-w-full tw-pb-3"},[_c('p',{staticClass:"mb-0 tw-px-0 card-title"},[_vm._v(" Bookings ")]),_c('p',{staticClass:"mb-0 tw-px-0 tw-cursor-pointer card-sub-title"},[_vm._v(" View ")])])}]

export { render, staticRenderFns }